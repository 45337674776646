var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.hideBlockTop)?_c('div',{staticClass:"table__header"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"pl-0",attrs:{"cols":"6"}},[_c('h2',{staticClass:"main-title"},[_vm._v(_vm._s(_vm.title))]),_c('total',{attrs:{"total":_vm.counts}})],1),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"6"}},[_c('div',{staticClass:"btn__row"},[_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","color":"primary","dark":""},on:{"click":function($event){return _vm.$emit('update')}}},[_c('v-icon',[_vm._v("loop")])],1),(_vm.addingRecordsVerification())?_c('v-btn',{attrs:{"outlined":"","color":"primary","dark":""},on:{"click":function($event){return _vm.onAdd()}}},[_c('v-icon',[_vm._v("add")]),_vm._v(" "+_vm._s(_vm.$t("buttons.add"))+" ")],1):_vm._e()],1)])],1)],1)],1):_vm._e(),(_vm.hideBlockBtn)?_c('div',{staticClass:"block__btn mb-4"},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-0"},[_c('v-col',{attrs:{"lg":"4","md":"6","sm":"6","cols":"12"}},[_c('v-btn',{staticClass:"mr-2 btn__task",class:{ active: _vm.activeButton === 1 },attrs:{"width":"100%"},on:{"click":function($event){(_vm.activeButton = 1),
                _vm.getTaskList({ book: 'taskDrivers', args: '?finished=false' })}}},[_vm._v(_vm._s(_vm.$t("buttons.inProgress")))])],1),_c('v-col',{attrs:{"lg":"4","md":"6","sm":"6","cols":"12"}},[_c('v-btn',{staticClass:"mr-2 btn__task",class:{ active: _vm.activeButton === 2 },attrs:{"width":"100%"},on:{"click":function($event){(_vm.activeButton = 2),
                _vm.getTaskList({ book: 'taskDrivers', args: '?finished=true' })}}},[_vm._v(_vm._s(_vm.$t("buttons.done")))])],1)],1)],1)],1):_vm._e(),_c('v-data-table',{staticClass:"elevation-3 table table-new table__custom",class:_vm.customClass,attrs:{"headers":_vm.visibleHeaders,"items":_vm.filteredData,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","options":_vm.options,"custom-sort":_vm.customSort,"loading":_vm.load,"sort-by":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.finished",fn:function(ref){
                var item = ref.item;
return [(item.finished == false)?_c('v-chip',{staticClass:"chip",attrs:{"color":"green","dark":""}},[_vm._v(" "+_vm._s(_vm.$t("settings.active"))+" ")]):_vm._e(),(item.finished == true)?_c('v-chip',{staticClass:"chip",attrs:{"color":"red","dark":""}},[_vm._v(" "+_vm._s(_vm.$t("settings.inActive"))+" ")]):_vm._e()]}},{key:"item.state",fn:function(ref){
                var item = ref.item;
return [(item.state == 'active')?_c('v-chip',{staticClass:"chip",attrs:{"color":"green","dark":""}},[_vm._v(" "+_vm._s(_vm.$t("settings.active"))+" ")]):_vm._e(),(item.state == 'inactive')?_c('v-chip',{staticClass:"chip",attrs:{"color":"red","dark":""}},[_vm._v(" "+_vm._s(_vm.$t("settings.inActive"))+" ")]):_vm._e()]}},{key:"item.connected",fn:function(ref){
                var item = ref.item;
return [(item.connected == true)?_c('v-chip',{staticClass:"chip",attrs:{"color":"green","dark":""}},[_vm._v(" "+_vm._s(_vm.$t("settings.active"))+" ")]):_vm._e(),(item.connected == false)?_c('v-chip',{staticClass:"chip",attrs:{"color":"red","dark":""}},[_vm._v(" "+_vm._s(_vm.$t("settings.inActive"))+" ")]):_vm._e()]}},{key:"item.is_active",fn:function(ref){
                var item = ref.item;
return [(item.is_active === true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check-circle ")]):_vm._e(),(item.is_active === false)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close-circle ")]):_vm._e()]}},{key:"item.type_seller",fn:function(ref){
                var item = ref.item;
return [(item.type_seller === true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check-circle ")]):_vm._e(),(item.type_seller === false)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close-circle ")]):_vm._e()]}},{key:"item.type_carrier",fn:function(ref){
                var item = ref.item;
return [(item.type_carrier === true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check-circle ")]):_vm._e(),(item.type_carrier === false)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close-circle ")]):_vm._e()]}},{key:"item.type_buyer",fn:function(ref){
                var item = ref.item;
return [(item.type_buyer === true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check-circle ")]):_vm._e(),(item.type_buyer === false)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close-circle ")]):_vm._e()]}},{key:"item.role_seller",fn:function(ref){
                var item = ref.item;
return [(item.role_seller === true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check-circle ")]):_vm._e(),(item.role_seller === false)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close-circle ")]):_vm._e()]}},{key:"item.role_carrier",fn:function(ref){
                var item = ref.item;
return [(item.role_carrier === true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check-circle ")]):_vm._e(),(item.role_carrier === false)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close-circle ")]):_vm._e()]}},{key:"item.role_buyer",fn:function(ref){
                var item = ref.item;
return [(item.role_buyer === true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check-circle ")]):_vm._e(),(item.role_buyer === false)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close-circle ")]):_vm._e()]}},{key:"item.active",fn:function(ref){
                var item = ref.item;
return [(item.active === true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check-circle ")]):_vm._e(),(item.ractive === false)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close-circle ")]):_vm._e()]}},{key:"header.actions",fn:function(){return [_c('p',{staticClass:"search_category",on:{"click":function($event){$event.stopPropagation();_vm.show = !_vm.show}}},[_c('v-icon',{attrs:{"color":"#cbd3e9","small":""}},[_vm._v("search")]),_c('span',[_vm._v(_vm._s(_vm.$t("searchCat")))])],1)]},proxy:true},(_vm.headers)?{key:"body.prepend",fn:function(){return [(_vm.show)?_c('tr',_vm._l((_vm.visibleHeaders),function(item){return _c('td',{key:("search_" + (item.value))},[(item.value !== 'actions')?_c('v-text-field',{staticClass:"table-search pa-0",attrs:{"append-icon":"mdi-magnify","hide-details":"","background-color":"#fff","rounded":"","single-line":""},on:{"input":function($event){return _vm.search($event, item.value)}}}):_c('v-icon',{attrs:{"small":""},on:{"click":function($event){_vm.show = false}}},[_vm._v("close")])],1)}),0):_vm._e()]},proxy:true}:null,{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [(_vm.hideBlockViewing)?_c('v-btn',{attrs:{"fab":"","x-small":"","tag":"router-link","to":("/task/" + (item.id)),"icon":"","center":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-eye ")])],1):_vm._e(),(_vm.verificationRole() && _vm.hideBlockEdit)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.hideBlockEdit)?_c('v-menu',{attrs:{"offset-x":"","left":"","nudge-top":"50%","nudge-left":"10px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [(_vm.verificationRole())?_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-close ")]):_vm._e()]}}],null,true)},[_c('deliteItem',{on:{"close":function($event){_vm.dialogDelete = false},"confirm":_vm.deleteItemConfirm}})],1):_vm._e()]}},{key:"no-data",fn:function(){return [_c('p',{staticClass:"nodata"},[_vm._v(_vm._s(_vm.$t("title.nodata")))])]},proxy:true}],null,true)}),(_vm.pageCounter > 1)?_c('div',{staticClass:"pagination-block"},[_c('v-pagination',{attrs:{"length":_vm.pageCounter,"total-visible":7,"circle":""},model:{value:(_vm._page),callback:function ($$v) {_vm._page=$$v},expression:"_page"}})],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('edit-item',{attrs:{"headers":_vm.toEditHeaders,"record":_vm.editedItem,"title":_vm.titleModal},on:{"close":_vm.close,"new":_vm.createRecord,"edit":_vm.editRecord}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }