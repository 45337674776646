<template>
  <div>
    <!-- Table header top -->
    <div class="table__header" v-if="hideBlockTop">
      <v-container fluid>
        <v-row class="pa-0">
          <v-col class="pl-0" cols="6">
            <h2 class="main-title">{{ title }}</h2>
            <total :total="counts" />
          </v-col>
          <v-col class="pr-0" cols="6">
            <div class="btn__row">
              <v-btn
                outlined
                color="primary"
                dark
                @click="$emit('update')"
                class="mr-2"
              >
                <v-icon>loop</v-icon>
              </v-btn>
              <v-btn
                outlined
                color="primary"
                dark
                @click="onAdd()"
                v-if="addingRecordsVerification()"
              >
                <v-icon>add</v-icon>
                {{ $t("buttons.add") }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- Block buttons-->
    <div class="block__btn mb-4" v-if="hideBlockBtn">
      <v-container fluid class="pa-0">
        <v-row class="pa-0">
          <v-col lg="4" md="6" sm="6" cols="12">
            <v-btn
              :class="{ active: activeButton === 1 }"
              class="mr-2 btn__task"
              width="100%"
              @click="
                (activeButton = 1),
                  getTaskList({ book: 'taskDrivers', args: '?finished=false' })
              "
              >{{ $t("buttons.inProgress") }}</v-btn
            >
          </v-col>
          <v-col lg="4" md="6" sm="6" cols="12">
            <v-btn
              width="100%"
              class="mr-2 btn__task"
              :class="{ active: activeButton === 2 }"
              @click="
                (activeButton = 2),
                  getTaskList({ book: 'taskDrivers', args: '?finished=true' })
              "
              >{{ $t("buttons.done") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Data table -->
    <v-data-table
      :headers="visibleHeaders"
      :items="filteredData"
      :items-per-page="itemsPerPage"
      hide-default-footer
      :options.sync="options"
      :custom-sort="customSort"
      class="elevation-3 table table-new table__custom"
      :class="customClass"
      :loading="load"
      sort-by="id"
    >
      <template v-slot:item.finished="{ item }">
        <v-chip color="green" class="chip" dark v-if="item.finished == false">
          {{ $t("settings.active") }}
        </v-chip>
        <v-chip color="red" class="chip" dark v-if="item.finished == true">
          {{ $t("settings.inActive") }}
        </v-chip>
      </template>

      <template v-slot:item.state="{ item }">
        <v-chip color="green" class="chip" dark v-if="item.state == 'active'">
          {{ $t("settings.active") }}
        </v-chip>
        <v-chip color="red" class="chip" dark v-if="item.state == 'inactive'">
          {{ $t("settings.inActive") }}
        </v-chip>
      </template>
      <template v-slot:item.connected="{ item }">
        <v-chip color="green" class="chip" dark v-if="item.connected == true">
          {{ $t("settings.active") }}
        </v-chip>
        <v-chip color="red" class="chip" dark v-if="item.connected == false">
          {{ $t("settings.inActive") }}
        </v-chip>
      </template>

      <template v-slot:item.is_active="{ item }">
        <v-icon color="green" v-if="item.is_active === true">
          mdi-check-circle
        </v-icon>
        <v-icon color="red" v-if="item.is_active === false">
          mdi-close-circle
        </v-icon>
      </template>

      <template v-slot:item.type_seller="{ item }">
        <v-icon color="green" v-if="item.type_seller === true">
          mdi-check-circle
        </v-icon>
        <v-icon color="red" v-if="item.type_seller === false">
          mdi-close-circle
        </v-icon>
      </template>

      <template v-slot:item.type_carrier="{ item }">
        <v-icon color="green" v-if="item.type_carrier === true">
          mdi-check-circle
        </v-icon>
        <v-icon color="red" v-if="item.type_carrier === false">
          mdi-close-circle
        </v-icon>
      </template>

      <template v-slot:item.type_buyer="{ item }">
        <v-icon color="green" v-if="item.type_buyer === true">
          mdi-check-circle
        </v-icon>
        <v-icon color="red" v-if="item.type_buyer === false">
          mdi-close-circle
        </v-icon>
      </template>

      <template v-slot:item.role_seller="{ item }">
        <v-icon color="green" v-if="item.role_seller === true">
          mdi-check-circle
        </v-icon>
        <v-icon color="red" v-if="item.role_seller === false">
          mdi-close-circle
        </v-icon>
      </template>

      <template v-slot:item.role_carrier="{ item }">
        <v-icon color="green" v-if="item.role_carrier === true">
          mdi-check-circle
        </v-icon>
        <v-icon color="red" v-if="item.role_carrier === false">
          mdi-close-circle
        </v-icon>
      </template>

      <template v-slot:item.role_buyer="{ item }">
        <v-icon color="green" v-if="item.role_buyer === true">
          mdi-check-circle
        </v-icon>
        <v-icon color="red" v-if="item.role_buyer === false">
          mdi-close-circle
        </v-icon>
      </template>

      <template v-slot:item.active="{ item }">
        <v-icon color="green" v-if="item.active === true">
          mdi-check-circle
        </v-icon>
        <v-icon color="red" v-if="item.ractive === false">
          mdi-close-circle
        </v-icon>
      </template>
      <!-- Headers button hide search -->
      <template v-slot:header.actions>
        <p class="search_category" @click.stop="show = !show">
          <v-icon color="#cbd3e9" small>search</v-icon>
          <span>{{ $t("searchCat") }}</span>
        </p>
      </template>

      <!-- Headers search -->
      <template v-slot:body.prepend v-if="headers">
        <tr v-if="show">
          <td v-for="item in visibleHeaders" :key="`search_${item.value}`">
            <v-text-field
              class="table-search pa-0"
              v-if="item.value !== 'actions'"
              append-icon="mdi-magnify"
              hide-details
              background-color="#fff"
              rounded
              single-line
              @input="search($event, item.value)"
            ></v-text-field>
            <v-icon v-else small @click="show = false">close</v-icon>
          </td>
        </tr>
      </template>

      <!-- Button edit, button delete -->
      <template v-slot:item.actions="{ item }">
        <!-- Button viewing -->

        <v-btn
          fab
          x-small
          tag="router-link"
          :to="`/task/${item.id}`"
          icon
          center
          v-if="hideBlockViewing"
        >
          <v-icon small> mdi-eye </v-icon>
        </v-btn>
        <!-- Edit -->
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
          v-if="verificationRole() && hideBlockEdit"
        >
          mdi-pencil
        </v-icon>

        <!-- Delete -->
        <v-menu
          offset-x
          left
          nudge-top="50%"
          nudge-left="10px"
          v-if="hideBlockEdit"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              v-if="verificationRole()"
              @click="deleteItem(item)"
            >
              mdi-close
            </v-icon>
          </template>

          <!-- Modal delete -->
          <deliteItem
            @close="dialogDelete = false"
            @confirm="deleteItemConfirm"
          />
        </v-menu>
      </template>

      <!-- No data -->
      <template v-slot:no-data>
        <p class="nodata">{{ $t("title.nodata") }}</p>
      </template>
    </v-data-table>

    <!-- Pagination -->

    <div class="pagination-block" v-if="pageCounter > 1">
      <v-pagination
        v-model="_page"
        :length="pageCounter"
        :total-visible="7"
        circle
      />
    </div>

    <!-- Modal edit -->
    <v-dialog v-model="dialog" max-width="500px">
      <edit-item
        @close="close"
        @new="createRecord"
        @edit="editRecord"
        :headers="toEditHeaders"
        :record="editedItem"
        :title="titleModal"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import total from "@/components/Total.vue";
import editItem from "@/components/EditItem.vue";
import deliteItem from "@/components/DeliteItem.vue";

export default {
  data: () => ({
    itemsPerPage: 10,
    searchFor: {},
    searchValue: "",
    key: "",
    ifSerching: false,
    show: false,
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    isEdit: false,
    options: {},
  }),
  components: { total, editItem, deliteItem },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    toEditHeaders: {
      type: Array,
      default: () => [],
    },
    toEditItem: {
      type: Object,
      default: null,
    },

    title: {
      type: String,
      default: "",
    },
    titleModal: {
      type: String,
      default: "",
    },
    counts: {
      type: Number,
      default: 0,
    },
    directory: {
      type: String,
      default: "",
    },
    hideBlockTop: {
      type: Boolean,
      default: false,
    },
    hideBlockEdit: {
      type: Boolean,
      default: false,
    },
    hideBlockViewing: {
      type: Boolean,
      default: false,
    },
    hideBlockBtn: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
      default: 1,
    },
    customClass: {
      type: String,
      default: "",
    },
    load: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    _page: {
      get() {
        return this.page;
      },
      set(page) {
        this.$emit("onPagination", page);
      },
    },
    pageCounter() {
      return Math.ceil(this.counts / 10);
    },
    editedItem: {
      get() {
        return this.toEditItem;
      },
      set(newVal) {
        this.$emit("changeEditItem", newVal);
      },
    },
    filteredData() {
      let datas = this.items.map(function (item) {
        let newItem = { ...item };
        const keyValues = Object.keys(newItem).map((key) => {
          if (newItem[key] !== null && typeof newItem[key] === "object") {
            newItem[key] = item[key].caption;
          }
          // if (
          //   newItem[key] !== null &&
          //   key === "finished" &&
          //   newItem[key] === true
          // ) {
          //   newItem[key] = "inActive";
          // }
          // if (
          //   newItem[key] !== null &&
          //   key === "finished" &&
          //   newItem[key] === false
          // ) {
          //   newItem[key] = "Active";
          // }
          // if (
          //   newItem[key] !== null &&
          //   key === "connected" &&
          //   newItem[key] === true
          // ) {
          //   newItem[key] = "Connected";
          // }
          // if (
          //   newItem[key] !== null &&
          //   key === "connected" &&
          //   newItem[key] === false
          // ) {
          //   newItem[key] = "inConnected";
          // }
          // if (
          //   newItem[key] !== null &&
          //   key === "active" &&
          //   newItem[key] === true
          // ) {
          //   newItem[key] = "Yes";
          // }
          // if (
          //   newItem[key] !== null &&
          //   key === "active" &&
          //   newItem[key] === false
          // ) {
          //   newItem[key] = "No";
          // }
          // if (
          //   ((newItem[key] !== null && key === "type_buyer") ||
          //     key === "type_carrier" ||
          //     key === "type_seller") &&
          //   newItem[key] === true
          // ) {
          //   newItem[key] = "Yes";
          // }
          // if (
          //   ((newItem[key] !== null && key === "type_buyer") ||
          //     key === "type_carrier" ||
          //     key === "type_seller") &&
          //   newItem[key] === false
          // ) {
          //   newItem[key] = "No";
          // }
          return newItem;
        });
        return Object.assign({}, ...keyValues);
      });
      if (!this.ifSerching) {
        return datas;
      } else {
        return datas.filter((item) => {
          if (item[this.key]) {
            return (
              item[this.key]
                .toString()
                .toLowerCase()
                .indexOf(this.searchValue.toLowerCase()) !== -1
            );
          }
        });
      }
    },

    visibleHeaders() {
      return this.headers.filter((header) => header.visible);
    },
    ...mapGetters(["ROLES", "ISUSER", "ISADMINCOMPANY", "ISADMIN"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.sortBy();
      },
      deep: true,
    },
  },

  methods: {
    getColor(finished) {
      if (finished === "Active") {
        return "green";
      } else return "red";
    },
    getColorCheck() {
      return "green";
    },
    getColorClose() {
      return "red";
    },
    customSort(items) {
      return items;
    },
    sortBy() {
      const { sortBy, sortDesc } = this.options;
      this.$emit("sortBy", { ordering_field: sortBy, desc: sortDesc });
    },
    onAdd() {
      this.dialog = true;
      this.$emit("add");
    },
    createRecord(newRecord) {
      this.$emit("createRecord", newRecord);
      this.close();
      this.loading = true;
    },
    editRecord(record) {
      this.$emit("editRecord", record);
      this.close();
    },
    search(val, key) {
      this.searchValue = val;
      this.key = key;
      this.ifSerching = true;
    },
    editItem(item) {
      if (this.verificationRole()) {
        this.$emit("getItemForEdit", item.id);
        this.isEdit = true;
        this.dialog = true;
      }
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = item;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let deleting = this.items;
      deleting.splice(this.editedIndex, 1);
      let payload = {
        id: this.editedItem.id,
        items: deleting,
      };
      this.$emit("deleteRecord", payload);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.editedItem = null;
      this.isEdit = false;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.editedItem = null;
    },
    addingRecordsVerification() {
      if (
        this.ISADMIN ||
        (this.ISADMINCOMPANY && !this.directory.match("company"))
      )
        return true;
      else return false;
    },
    verificationRole() {
      if (
        this.ISADMIN ||
        (this.ISADMINCOMPANY && this.ROLES.seller) ||
        (this.ISUSER && this.ROLES.seller) ||
        (this.ISADMINCOMPANY && this.directory.match("company"))
      ) {
        return true;
      } else return false;
    },
  },
};
</script>
<style lang="scss" >
.v-text-field {
  margin: 0;
  padding: 0;
}
.btn__row {
  display: flex;
  justify-content: flex-end;
  &:last-child {
    margin-top: 10px;
  }
}
.pagination-block {
  margin-top: 40px;
}
.table-search {
  max-width: 200px;
}
.nodata {
  margin: 0;
}

// .table-new {
//   thead {
//     th:last-child {
//       display: none !important;
//     }
//   }
// }
// .table-new {
//   tbody {
//     td:last-child {
//       display: none !important;
//     }
//   }
// }
</style>
