<template>
  <v-card min-height="100vh" class="pa-6">
    <p class="text-right pa-0">
      <v-icon @click="close">close</v-icon>
    </p>
    <p class="pa-0 mt-2 text-center title-modal">
      {{ formTitle }}
    </p>

    <v-card-text class="pa-0 mt-2">
      <v-row no-gutters>
        <v-col cols="12">
          <different-fields
            v-for="header in labels"
            :key="header.value"
            :headerObj="header"
            v-model="newItem[header.value]"
            :order="orderEdit"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="pa-0 mt-4">
      <v-btn
        color="primary"
        block
        @click="save()"
        height="38"
        :disabled="isValid"
      >
        {{ $t("buttons.save") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import DifferentFields from "@/components/differentFields";

export default {
  name: "EditItem",
  components: { DifferentFields },
  data() {
    return {
      newItem: {},
      isValid: false,
    };
  },
  props: {
    record: {
      type: Object,
      default: null,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    orderEdit: {
      type: [String, Number, null],
      default: null,
    },
  },
  computed: {
    formTitle() {
      return !this.title ? (this.record ? "Edit" : "Add") : this.title;
    },

    labels() {
      return this.headers.filter(
        (item) => item.value !== "id" && item.value !== "actions"
      );
    },
  },
  watch: {
    record: {
      immediate: true,
      handler: function (newVal) {
        if (newVal) {
          this.newItem = { ...this.record };
        } else {
          this.generateEmptyValue();
        }
      },
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    generateEmptyValue() {
      if (this.headers.length > 0) {
        let map = new Map();
        this.labels.forEach((item) => {
          if (item.type === "BooleanField") {
            map.set(item.value, false);
          } else map.set(item.value, "");
        });
        this.newItem = Object.fromEntries(map);
      }
    },
    save() {
      let generateEmit;
      let temp = JSON.stringify(this.newItem);
      let result = JSON.parse(temp);
      for (let el in result) {
        if (el === "creator" || el === "driver") {
          for (let key in result[el]) {
            if (key === "name") {
              result[el]["email"] = result[el][key];
              delete result[el][key];
            }
          }
        } else if (el === "vehicle") {
          for (let key in result[el]) {
            if (key === "name") {
              result[el]["state_number"] = result[el][key];
              delete result[el][key];
            }
          }
        }
      }
      this.record ? (generateEmit = "edit") : (generateEmit = "new");
      this.$emit(generateEmit, result);
      // this.generateEmptyValue();
      this.newItem = { ...this.record };
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.title-modal {
  width: 100%;
  display: block;
  font-weight: bold;
  font-size: 26px;
  line-height: 26px;
  color: #111421;
}
</style>
